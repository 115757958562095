import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { getBlockContent, getBlockImage } from "../utils/helpers";
// Layout
import Layout from "../components/layout/Layout";
import Accordion from "../components/layout/Accordion";

// Primitives
import ProgramItem from "../components/primitives/ProgramItem";

const Program2021 = ({ data }) => {
  const { nodes: programData = [] } = data.allSanityProgram;
  const { pageTitle, shoutOut, metaInfo, title, slug, _rawContent } =
    data?.sanityPages || {};
  const { programArray = [] } = programData[0] || [{}];

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader || pageTitle,
    description:
      metaInfo?.description || getBlockContent(_rawContent) || shoutOut,
    fullSlug: slug?.current,
    image: metaInfo?.image || getBlockImage(_rawContent)?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage(_rawContent)?.alt,
  };

  return (
    <Layout metaInfo={metaStructure}>
      <div>
        {/* Page heading */}
        <div
          className="
            flex flex-wrap justify-between
            bg-black text-white
            w-full
            py-8 px-4
            mb-2
          "
        >
          {/* <h1 className="text-2xl w-full md:w-1/4">{pageTitle}</h1> */}

          {shoutOut && (
            <div
              className="
                self-end
                relative
                md:max-w-xs
                mt-8 -mb-8
                p-4
                transform rotate-4
                bg-white text-black
                border border-black
              "
            >
              <p className="netlife-sans">{shoutOut}</p>
            </div>
          )}
        </div>

        {/* Speakers link */}
        <Link to={`/speakers`}>
          <div
            className="
              flex justify-between
              bg-green text-black
              w-full p-4 mb-2
              hover:bg-black hover:text-white"
          >
            <h2 className="text-2xl uppercase">Speakers</h2>
            <h2 className="text-2xl">→</h2>
          </div>
        </Link>

        <Accordion alignLeft>
          {programArray.map(item => (
            <div key={item._key} data-header={item.postTitle} className="mb-2">
              <ProgramItem item={item} />
            </div>
          ))}
        </Accordion>

        <div className="mb-24 mt-24 uppercase text-center">
          LATE TO THE PARTY?
          <br />
          <a
            href="/program2020"
            className="underline hover:text-green-dark"
          >
            check out last years talks here
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Program2021;

export const query = graphql`
  query {
    sanityPages(slug: { current: { eq: "program2021" } }) {
      id
      pageTitle
      shoutOut
      _rawContent(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityProgram(filter: { programYear: { eq: "2021" } }) {
      nodes {
        id
        programArray {
          _key
          _type
          postTitle
          postSubtitle
          slotRef {
            _key
            _type
            linkToTalk
            ref {
              ... on SanityTalk {
                _type
                id
                title
                slug {
                  current
                }
                duration
                time
                foredragsholdere {
                  name
                  stilling
                  slug {
                    current
                  }
                }
                sceneRef {
                  title
                }
              }
              ... on SanityWorkshop {
                _type
                id
                title
                slug {
                  current
                }
                duration
                time
                foredragsholdere {
                  name
                  stilling
                  slug {
                    current
                  }
                }
                sceneRef {
                  title
                }
              }
              ... on SanityArticle {
                _type
                id
                title
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  }
`;
